@mixin horizontal {
  @media (max-width: 767.9999px) {
    @content;
  }
}
@mixin vertical {
  @media (min-width: 768px) {
    @content;
  }
}

#content {
  display: flex;
  flex-direction: column;
}

s-schedule-outer {
  flex: 1;
  display: block;
  @include horizontal {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    writing-mode: vertical-lr;
  }
}

s-schedule {
  display: grid;
  @include vertical {
    width: 100%;
    grid-template-columns: auto auto repeat(var(--total-tracks), 1fr);
    grid-template-rows: auto repeat(
        calc(var(--day-end) - var(--day-start)),
        1fr
      );
  }
  @include horizontal {
    height: 100%;
    width: calc((var(--day-end) - var(--day-start)) * 5px);
    grid-template-rows: auto auto repeat(var(--total-tracks), 1fr);
    grid-template-columns: auto repeat(
        calc(var(--day-end) - var(--day-start)),
        1fr
      );
    writing-mode: horizontal-tb;
  }
}

s-schedule a.session {
  z-index: 1;
  color: inherit;
  text-decoration: none;
  background-color: rgba($wattle-leaf, 0.2);
  .backstage &.session-L,
  .backstage &.session-None {
    background-color: rgba($wattle-leaf, 0.5);
  }
  border-radius: 5px;
  padding: 7px;
  margin: 2px;
  @include vertical {
    grid-column-start: calc(var(--track) + 2);
    grid-column-end: span 1;
    grid-row-start: calc(var(--start) - var(--day-start) + 2);
    grid-row-end: calc(var(--end) - var(--day-start) + 2);
    font-size: 16px;
  }
  @include horizontal {
    grid-row-start: calc(var(--track) + 2);
    grid-row-end: span 1;
    grid-column-start: calc(var(--start) - var(--day-start) + 2);
    grid-column-end: calc(var(--end) - var(--day-start) + 2);
    font-size: 14px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1em;

  .speaker {
    font-size: 0.75em;
    font-weight: bold;
  }
  .time {
    font-size: 0.6em;
    font-weight: bold;
    font-family: $heading-font;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

s-track,
s-tz-header {
  text-align: center;
  padding: 5px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  font-weight: bold;
  -webkit-backdrop-filter: blur(4px);
  @include vertical {
    grid-row: 1;
    grid-column: calc(var(--track) + 2);
  }
  @include horizontal {
    grid-column: 1;
    grid-row: calc(var(--track) + 2);
  }
}

s-track {
  @include horizontal {
    writing-mode: vertical-rl;
    transform: rotate(0.5turn);
    font-size: 13px;
  }
}

s-tz-header {
  @include vertical {
    grid-column: 1;
    &.local {
      grid-column: 2;
    }
  }
  @include horizontal {
    grid-row: 1;
    &.local {
      grid-row: 2;
    }
  }
}

s-time,
s-now {
  color: #aaa;
  padding: 3px;
  @include vertical {
    text-align: center;
    grid-column: 1;
    &.local {
      grid-column: 2;
    }
    grid-row-start: calc(var(--at) - var(--day-start) + 2);
    grid-row-end: span 60;
  }
  @include horizontal {
    grid-row: 1;
    &.local {
      grid-row: 2;
    }
    grid-column-start: calc(var(--at) - var(--day-start) + 2);
    grid-column-end: span 60;
  }
  z-index: -2;
}

s-time-rule,
s-now-rule {
  @include vertical {
    grid-column-start: 1;
    grid-column-end: calc(var(--total-tracks) + 3);
    grid-row: calc(var(--at) - var(--day-start) + 2);
  }
  @include horizontal {
    grid-row-start: 1;
    grid-row-end: calc(var(--total-tracks) + 3);
    grid-column: calc(var(--at) - var(--day-start) + 2);
  }
}

s-time-rule {
  @include vertical {
    border-top: 1px solid #ddd;
  }
  @include horizontal {
    border-left: 1px solid #ddd;
  }
  z-index: -2;
}

s-now-rule {
  @include vertical {
    border-top: 1px solid rgba($red-centre, 0.7);
  }
  @include horizontal {
    border-left: 1px solid red;
  }
  z-index: -1;
}

.switcher {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border: 2px solid $wattle-leaf;
  border-radius: 7px;
  padding: 2px;
  space-between: 2px;
  margin-bottom: 20px;
  @include horizontal {
    margin-bottom: 5px;
  }

  a {
    border-radius: 4px;
    margin-right: 2px;
    font-family: $heading-font;
    color: $wattle-leaf;
    font-weight: bold;
    text-decoration: none;
    font-size: 20px;
    padding: 6px 12px;
    @include horizontal {
      font-size: 15px;
      padding: 3px 6px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: rgba($wattle-leaf, 0.2);
    }
    &.selected {
      background-color: $wattle-leaf;
      color: white;
    }
  }
}

.tz {
  text-align: center;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.6);
}
